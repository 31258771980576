import { Injectable } from "@angular/core";

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Separator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Icon {
  type: string;
  name: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: Icon;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: "",
    name: "Getting Started",
    type: "separator",
    icon: { type: "normal", name: "av_timer" }
  },
  {
    state: "/start/introduction",
    name: "Introduction",
    type: "link",
    icon: { type: "svg", name: "icon_introduction" }
  },
  {
    state: "/start/best-practices",
    name: "Best Practices",
    type: "link",
    icon: { type: "svg", name: "icon_best_practices" }
  },
  {
    state: "/start/faq",
    name: "FAQ",
    type: "link",
    icon: { type: "svg", name: "icon_faq" }
  },
  {
    state: "/start/contacts",
    name: "Contacts",
    type: "link",
    icon: { type: "svg", name: "icon_contacts" }
  },
  {
    state: "/start/announcements",
    name: "Announcements",
    type: "link",
    icon: { type: "svg", name: "icon_announcements" }
  },
  {
    state: "",
    name: "Test Integration",
    type: "separator",
    icon: { type: "normal", name: "device_hub" }
  },
  {
    state: "/test/authorization",
    name: "API Authentication",
    type: "link",
    icon: { type: "svg", name: "icon_api_authentication" }
  },
  {
    state: "/test/data-type",
    name: "Data Type and Validation",
    type: "link",
    icon: { type: "svg", name: "icon_datatype" }
  },
  {
    state: "/test/evaluating-api",
    name: "Evaluate Response",
    type: "link",
    icon: { type: "svg", name: "icon_evaluate_response" }
  },
  {
    state: "/test/error-api",
    name: "Error Codes",
    type: "link",
    icon: { type: "svg", name: "icon_error_api" }
  },
  {
    state: "/test/cards",
    name: "Cards",
    type: "link",
    icon: { type: "svg", name: "icon_test_cards" }
  },
  {
    state: "/test/amounts",
    name: "Amounts",
    type: "link",
    icon: { type: "svg", name: "icon_test_amounts" }
  },
  //{
  //  state: "/test/crypto",
  //  name: "Crypto",
  //  type: "link",
  //  icon: { type: "svg", name: "icon_test_crypto" }
  //},
  {
    state: "/test/postman-collection",
    name: "Postman",
    type: "link",
    icon: { type: "svg", name: "icon_postman" }
  },

  {
    state: "",
    name: "Integration Reference",
    type: "separator",
    icon: { type: "normal", name: "device_hub" }
  },
  {
    state: "/payment-api/reference",
    name: "Payment",
    type: "sub",
    icon: { type: "svg", name: "icon_payment" },
    children: [
      {
        state: "initiate",
        name: "Initiate",
        type: "link"
      },
      {
        state: "process-auth",
        name: "Process Authentication",
        type: "link"
      },
      {
        state: "sale",
        name: "Sale",
        type: "link"
      },
      {
        state: "authorize",
        name: "Authorize",
        type: "link"
      },
      {
        state: "reverse",
        name: "Reverse",
        type: "link"
      },
      {
        state: "capture",
        name: "Capture",
        type: "link"
      },
      {
        state: "refund",
        name: "Refund",
        type: "link"
      },
      {
        state: "cancel",
        name: "Cancel",
        type: "link"
      },
      {
        state: "review-fraud",
        name: "Review Fraud",
        type: "link"
      },
      {
        state: "get-order",
        name: "Get Order",
        type: "link"
      },
      {
        state: "get-order-by-reference",
        name: "Get Order By Reference",
        type: "link"
      }
    ]
  },
  {
    state: "/token-api/reference",
    name: "Tokenization",
    type: "sub",
    icon: { type: "svg", name: "icon_tokenization" },
    children: [
      {
        state: "create-token",
        name: "Create Token",
        type: "link"
      },
      {
        state: "get-token",
        name: "Get Token",
        type: "link"
      },
      {
        state: "delete-token",
        name: "Delete Token",
        type: "link"
      }
    ]
  },
  {
    state: "/bnpl-api/reference",
    name: "BNPL",
    type: "sub",
    icon: { type: "svg", name: "icon_bnpl" },
    children: [
      {
        state: "check-bnpl-options-tabby",
        name: "Check BNPL - Tabby",
        type: "link"
      },
      {
        state: "check-bnpl-options-valu",
        name: "Check BNPL - Valu",
        type: "link"
      }
    ]
  },
  {
    state: "/subscription-api/reference",
    name: "Subscription",
    type: "sub",
    icon: { type: "svg", name: "icon_subscription" },
    children: [
      {
        state: "get-subscription",
        name: "Retrieve Subscription",
        type: "link"
      },
      {
        state: "cancel-subscription",
        name: "Cancel Subscription",
        type: "link"
      }
    ]
  },
  {
    state: "/offer-api/reference",
    name: "Offer",
    type: "sub",
    icon: { type: "svg", name: "icon_offers" },
    children: [
      {
        state: "check-offer-eligibility",
        name: "Check Eligibility",
        type: "link"
      }
    ]
  },
  {
    state: "/emi-api/reference",
    name: "EMI",
    type: "sub",
    icon: { type: "svg", name: "icon_emi" },
    children: [
      {
        state: "check-emi-eligibility",
        name: "Check EMI Eligibility",
        type: "link"
      },
      {
        state: "get-emi-plans",
        name: "Get EMI Plans",
        type: "link"
      },
      {
        state: "update-emi-status",
        name: "Update EMI Status",
        type: "link"
      }
    ]
  },
  {
    state: "/webhook",
    name: "Webhook",
    type: "link",
    icon: { type: "svg", name: "icon_webhook" }
  },
  {
    state: "/sdk/reference",
    name: "SDK",
    type: "sub",
    icon: { type: "svg", name: "icon_sdk" },
    children: [
      {
        state: "android",
        name: "Android",
        type: "link"
      },
      {
        state: "ios",
        name: "iOS",
        type: "link"
      },
      {
        state: "js",
        name: "JavaScript",
        type: "link"
      }
    ]
  },

  {
    state: "",
    name: "Payment Methods",
    type: "separator",
    icon: { type: "normal", name: "device_hub" }
  },
  {
    state: "/payment-method/card",
    name: "Cards",
    type: "sub",
    icon: { type: "svg", name: "icon_cards" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/applepay",
    name: "Apple Pay",
    type: "sub",
    icon: { type: "svg", name: "icon_applepay" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/googlepay",
    name: "Google Pay",
    type: "sub",
    icon: { type: "svg", name: "icon_googlepay" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/samsungpay",
    name: "Samsung Pay",
    type: "sub",
    icon: { type: "svg", name: "icon_samsungpay" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/clicktopay",
    name: "Click to Pay",
    type: "sub",
    icon: { type: "svg", name: "icon_clicktopay" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/paypal",
    name: "PayPal",
    type: "sub",
    icon: { type: "svg", name: "icon_paypal" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/omannet",
    name: "OmanNet",
    type: "sub",
    icon: { type: "svg", name: "icon_omannet" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/knet",
    name: "KNET",
    type: "sub",
    icon: { type: "svg", name: "icon_knet" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/benefit",
    name: "BENEFIT",
    type: "sub",
    icon: { type: "svg", name: "icon_benefit" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/payment-method/upg",
    name: "UPG",
    type: "sub",
    icon: { type: "svg", name: "icon_upg" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  //{
  //  state: "/payment-method/crypto",
  //  name: "Crypto",
  //  type: "sub",
  //  icon: { type: "svg", name: "icon_crypto" },
  //  children: [
  //    {
  //      state: "introduction",
  //      name: "Introduction",
  //      type: "link"
  //    },
  //    {
  //      state: "direct",
  //      name: "Direct",
  //      type: "link"
  //    },
  //    {
  //      state: "hosted",
  //      name: "Hosted",
  //      type: "link"
  //    }
  //  ]
  //},

  {
    state: "",
    name: "Billing",
    type: "separator",
    icon: { type: "normal", name: "device_hub" }
  },
  {
    state: "/billing/bnpl",
    name: "BNPL",
    type: "sub",
    icon: { type: "svg", name: "icon_bnpl" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct-tabby",
        name: "Direct - Tabby",
        type: "link"
      },
      {
        state: "direct-valu",
        name: "Direct - Valu",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/billing/subscription",
    name: "Subscription",
    type: "sub",
    icon: { type: "svg", name: "icon_subscription" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "recurring",
        name: "Recurring",
        type: "link"
      },
      {
        state: "unscheduled",
        name: "Unscheduled",
        type: "link"
      }
    ]
  },
  {
    state: "/billing/offer",
    name: "Offer",
    type: "sub",
    icon: { type: "svg", name: "icon_offers" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },
  {
    state: "/billing/emi",
    name: "EMI",
    type: "sub",
    icon: { type: "svg", name: "icon_emi" },
    children: [
      {
        state: "introduction",
        name: "Introduction",
        type: "link"
      },
      {
        state: "direct",
        name: "Direct",
        type: "link"
      },
      {
        state: "hosted",
        name: "Hosted",
        type: "link"
      }
    ]
  },

  {
    state: "",
    name: "E-commerce Platforms",
    type: "separator",
    icon: { type: "normal", name: "device_hub" }
  },
  {
    state: "/plugin/magento",
    name: "Magento",
    type: "link",
    icon: { type: "svg", name: "icon_magento" }
  },
  {
    state: "/plugin/opencart",
    name: "OpenCart",
    type: "link",
    icon: { type: "svg", name: "icon_opencart" }
  },
  {
    state: "/plugin/shopify",
    name: "Shopify",
    type: "link",
    icon: { type: "svg", name: "icon_shopify" }
  },
  {
    state: "/plugin/woocommerce",
    name: "WooCommerce",
    type: "link",
    icon: { type: "svg", name: "icon_woocommerce" }
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
