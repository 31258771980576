import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { ScrollManagerService } from '../../services/scroll-manager.service';
import { NavElems } from '../../models/nav-elems';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { Title } from '@angular/platform-browser';

import { titleMap } from './titlemap.data';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.css']
})

export class FullComponent implements OnDestroy, OnInit {
  currentYear = new Date().getFullYear();
  mobileQuery: MediaQueryList;
  tabQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  sidebarOpened;

  private router: Router;
  private titleService: Title;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  private _tabQueryListener: () => void;


  public urls = null;
  public isSpinnerVisible = false;
  constructor(
    router: Router,
    titleService: Title,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems, public _scroll: ScrollManagerService
  ) {
    this.router = router;
    this.titleService = titleService;
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this.tabQuery = media.matchMedia('(min-width: 900px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this._tabQueryListener = () => changeDetectorRef.detectChanges();
    this.tabQuery.addEventListener('change', this._tabQueryListener);

    this._scroll
      .pageHashUrlElement
      .subscribe((data: NavElems) => {
        setTimeout(() => {
          this.urls = data;
        }, 1);
      });

    this._scroll
      .showSpinner
      .subscribe((isSpinnerVisible) => {
        setTimeout(() => {
          this.isSpinnerVisible = isSpinnerVisible;
        },3);
        
      });
  }

  ngOnInit(): void {
    // Set the title of page on navigation end
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var map = titleMap[event.url];
        if (map) {
          this.titleService.setTitle(map.title);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.tabQuery.removeEventListener('change', this._tabQueryListener);
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this._scroll.pageHashUrlElement.unsubscribe();
  }
}
