const brand = `noon payments`;

const gettingStartedSection = `Getting Started`;
const testDataSection = `Test Integration`;
const apiReferenceSection = `Integration Reference`;
const cardsSection = `card`;
const googlePaySection = `Google Pay`;
const applePaySection = `Apple Pay`;
const samsungPaySection = `Samsung Pay`;
const paypalSection = `PayPal`;
const clickToPay = `Click to Pay`;
const omanNetSection = `OmanNet`;
const knetSection = `KNET`;
const benefitSection = `BENEFIT`;
const upgSection = `UPG`;
const cryptoSection = `Crypto`;
const bnplSection = `BNPL`;
const subscriptionSection = `Subscriptions`;
const offersSection = `Offers`;
const emiSection = `EMI`;
const sdkSection = `SDK`;
const pluginsSection = `Plugins`;
const threedsFlowSection = `3DS Flow`;

export const titleMap = {
  // Getting Started
  "/start/introduction": { title: `${gettingStartedSection} - Introduction - ${brand}`, meta: "" },
  "/start/best-practices": { title: `${gettingStartedSection} - Best Practices - ${brand}`, meta: "" },
  "/start/faq": { title: `${gettingStartedSection} - FAQ - ${brand}`, meta: "" },
  "/start/contacts": { title: `${gettingStartedSection} - Contacts - ${brand}`, meta: "" },
  "/start/announcements": { title: `${gettingStartedSection} - Announcements - ${brand}`, meta: "" },


  // Test Integration
  "/test/authorization": { title: `${testDataSection} - API Authentication - ${brand}`, meta: "" },
  "/test/data-type": { title: `${testDataSection} - Data Type and Validation - ${brand}`, meta: "" },
  "/test/evaluating-api": { title: `${testDataSection} - Evaluate Response - ${brand}`, meta: "" },
  "/test/error-api": { title: `${testDataSection} - Error Codes - ${brand}`, meta: "" },
  "/test/card": { title: `${testDataSection} - Test cards - ${brand}`, meta: "" },
  "/test/amounts": { title: `${testDataSection} - Test Amounts - ${brand}`, meta: "" },
  "/test/crypto": { title: `${testDataSection} - Test Crypto - ${brand}`, meta: "" },
  "/test/postman-collection": { title: `${testDataSection} - Postman - ${brand}`, meta: "" },


  // Integration Reference
  "/payment-api/reference/initiate": { title: `${apiReferenceSection} - Initiate - ${brand}`, meta: "" },
  "/payment-api/reference/process-auth": { title: `${apiReferenceSection} - Process Authentication - ${brand}`, meta: "" },
  "/payment-api/reference/sale": { title: `${apiReferenceSection} - Sale - ${brand}`, meta: "" },
  "/payment-api/reference/authorize": { title: `${apiReferenceSection} - Authorize - ${brand}`, meta: "" },
  "/payment-api/reference/reverse": { title: `${apiReferenceSection} - Reverse - ${brand}`, meta: "" },
  "/payment-api/reference/capture": { title: `${apiReferenceSection} - Capture - ${brand}`, meta: "" },
  "/payment-api/reference/refund": { title: `${apiReferenceSection} - Refund - ${brand}`, meta: "" },
  "/payment-api/reference/cancel": { title: `${apiReferenceSection} - Cancel - ${brand}`, meta: "" },
  "/payment-api/reference/get-order": { title: `${apiReferenceSection} - Get Order - ${brand}`, meta: "" },
  "/payment-api/reference/get-order-by-reference": { title: `${apiReferenceSection} - Get Order By Reference - ${brand}`, meta: "" },
  "/payment-api/reference/review-fraud": { title: `${apiReferenceSection} - Review Fraud - ${brand}`, meta: "" },

  "/token-api/reference/create-token": { title: `${apiReferenceSection} - Create Token - ${brand}`, meta: "" },
  "/token-api/reference/get-token": { title: `${apiReferenceSection} - Get Token - ${brand}`, meta: "" },
  "/token-api/reference/delete-token": { title: `${apiReferenceSection} - Delete Token - ${brand}`, meta: "" },

  "/bnpl-api/reference/check-bnpl-options-tabby": { title: `${apiReferenceSection} - Check BNPL Options - Tabby - ${brand}`, meta: "" },
  "/bnpl-api/reference/check-bnpl-options-value": { title: `${apiReferenceSection} - Check BNPL Options - Value - ${brand}`, meta: "" },

  "/subscription-api/reference/get-subscription": { title: `${apiReferenceSection} - Retrieve Subscription - ${brand}`, meta: "" },
  "/subscription-api/reference/cancel-subscription": { title: `${apiReferenceSection} - Cancel Subscription - ${brand}`, meta: "" },

  "/offer-api/reference/check-offer-eligibility": { title: `${apiReferenceSection} - Check Offer Eligibility - ${brand}`, meta: "" },

  "/emi-api/reference/check-emi-eligibility": { title: `${apiReferenceSection} - Check EMI Eligibility - ${brand}`, meta: "" },
  "/emi-api/reference/get-emi-plans": { title: `${apiReferenceSection} - Get EMI Plans - ${brand}`, meta: "" },
  "/emi-api/reference/update-emi-status": { title: `${apiReferenceSection} - Update EMI Status - ${brand}`, meta: "" },

  "/webhook": { title: `${apiReferenceSection} - Webhook - ${brand}`, meta: "" },

  "/sdk/reference/android": { title: `${sdkSection} - Android - ${brand}`, meta: "" },
  "/sdk/reference/ios": { title: `${sdkSection} - iOS - ${brand}`, meta: "" },
  "/sdk/reference/js": { title: `${sdkSection} - JavaScript - ${brand}`, meta: "" },


  // Payment Methods
  "/payment-method/card/introduction": { title: `${cardsSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/card/direct": { title: `${cardsSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/card/hosted": { title: `${cardsSection} - Hosted Checkout Integration - ${brand}`, meta: "" },
  "/payment-method/card/js-integration": { title: `${cardsSection} - JS Integration - ${brand}`, meta: "" },

  "/payment-method/applepay/introduction": { title: `${applePaySection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/applepay/direct": { title: `${applePaySection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/applepay/hosted": { title: `${applePaySection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/googlepay/introduction": { title: `${googlePaySection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/googlepay/direct": { title: `${googlePaySection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/googlepay/hosted": { title: `${googlePaySection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/samsungpay/introduction": { title: `${samsungPaySection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/samsungpay/direct": { title: `${samsungPaySection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/samsungpay/hosted": { title: `${samsungPaySection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/paypal/introduction": { title: `${paypalSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/paypal/direct": { title: `${paypalSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/paypal/hosted": { title: `${paypalSection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/clicktopay/introduction": { title: `${clickToPay} - Introduction - ${brand}`, meta: "" },
  "/payment-method/clicktopay/direct": { title: `${clickToPay} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/clicktopay/hosted": { title: `${clickToPay} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/omannet/introduction": { title: `${omanNetSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/omannet/direct": { title: `${omanNetSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/omannet/hosted": { title: `${omanNetSection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/knet/introduction": { title: `${knetSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/knet/direct": { title: `${knetSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/knet/hosted": { title: `${knetSection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/benefit/introduction": { title: `${benefitSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/benefit/direct": { title: `${benefitSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/benefit/hosted": { title: `${benefitSection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/upg/introduction": { title: `${upgSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/upg/direct": { title: `${upgSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/upg/hosted": { title: `${upgSection} - Hosted Checkout - ${brand}`, meta: "" },

  "/payment-method/crypto/introduction": { title: `${cryptoSection} - Introduction - ${brand}`, meta: "" },
  "/payment-method/crypto/direct": { title: `${cryptoSection} - Direct Integration - ${brand}`, meta: "" },
  "/payment-method/crypto/hosted": { title: `${cryptoSection} - Hosted Checkout - ${brand}`, meta: "" },


  // Billing
  "/billing/bnpl/introduction": { title: `${bnplSection} - Introduction - ${brand}`, meta: "" },
  "/billing/bnpl/direct-tabby": { title: `${bnplSection} - Direct Integration - Tabby - ${brand}`, meta: "" },
  "/billing/bnpl/direct-valu": { title: `${bnplSection} - Direct Integration - Valu - ${brand}`, meta: "" },
  "/billing/bnpl/hosted": { title: `${bnplSection} - Hosted Integration - ${brand}`, meta: "" },

  "/billing/subscription/introduction": { title: `${subscriptionSection} - Introduction - ${brand}`, meta: "" },
  "/billing/subscription/recurring": { title: `${subscriptionSection} - Recurring Payments - ${brand}`, meta: "" },
  "/billing/subscription/unscheduled": { title: `${subscriptionSection} - Unscheduled Payments - ${brand}`, meta: "" },

  "/billing/offer/introduction": { title: `${offersSection} - Introduction - ${brand}`, meta: "" },
  "/billing/offer/direct": { title: `${offersSection} - Direct Integration - ${brand}`, meta: "" },
  "/billing/offer/hosted": { title: `${offersSection} - Hosted Integration - ${brand}`, meta: "" },

  "/billing/emi/introduction": { title: `${emiSection} - Introduction - ${brand}`, meta: "" },
  "/billing/emi/direct": { title: `${emiSection} - Direct Integration - ${brand}`, meta: "" },
  "/billing/emi/hosted": { title: `${emiSection} - Hosted Integration - ${brand}`, meta: "" },


  // E-commerce Platforms
  "/plugin/magento": { title: `${pluginsSection} - Magento - ${brand}`, meta: "" },
  "/plugin/opencart": { title: `${pluginsSection} - OpenCart - ${brand}`, meta: "" },
  "/plugin/shopify": { title: `${pluginsSection} - Shopify - ${brand}`, meta: "" },
  "/plugin/woocommerce": { title: `${pluginsSection} - WooCommerce - ${brand}`, meta: "" },
}
