import { Component, Input } from '@angular/core';
import { NavElems } from '../../models/nav-elems';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.css']
})
export class NavigationPanelComponent {
  @Input() navigationData: NavElems;

  scroll(data: NavElems) {
    window.scrollTo({
      top: data.offset
    });
  }
}
