
import { environment } from '../../../environments/environment';

export const BASE_LOGOS_PATH = 'assets/images/logos';
export const BASE_ICONS_PATH = 'assets/images/icons';
export const BASE_DOCUMENTS_PATH = 'assets/documents';

export const CDN_RELATIVE_PATH = {
    LOGOS_PAYMENT_METHODS: `${environment.ImageCDNPathKey}${BASE_LOGOS_PATH}/payment-methods`,
    ICONS_PAYMENT_METHODS: `${environment.ImageCDNPathKey}${BASE_ICONS_PATH}/payment-methods`,
    DOCUMENTS: `${environment.ImageCDNPathKey}${BASE_DOCUMENTS_PATH}`
}
