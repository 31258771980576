<div>
  <ul *ngIf="navigationData">
    <li>
      <a class='btn' style="cursor: pointer;" [ngClass]="{'active':navigationData && navigationData.activated === true}"
         (click)="scroll(navigationData)">
        {{ navigationData.title }}
      </a>
      <app-navigation-panel *ngFor="let elem of navigationData.elems" [navigationData]='elem'>
      </app-navigation-panel>
    </li>
  </ul>
</div>
