<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative topbar-noon" style="position: fixed;">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <!-- Logo icon -->
        <strong>
          <!-- Light Logo icon -->
          <img src="assets/images/noonpayments_logo_figma.svg" alt="homepage" class="light-logo docs-logo">
        </strong>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span fxShow="false" fxShow.gt-xs>
          <!-- Light Logo text -->
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed" class="side-menu-toggle-button">
      <mat-icon>menu</mat-icon>
    </button>
    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <form class="app-search">
      <input type="text" class="form-control" placeholder="Search &amp; enter">
      <a class="cl-srh-btn">
        <em class="ti-close"></em>
      </a>
    </form>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible side-menu-toggle-button" fxShow="false" fxShow.gt-xs>
      <mat-icon *ngIf="end.opened === true">keyboard_arrow_right</mat-icon>
      <mat-icon *ngIf="end.opened === false">keyboard_arrow_left</mat-icon>
    </button>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 65 : 65"
                         [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" style="position: fixed;" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
                 [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false"
                 [perfectScrollbar]="config" [style.marginTop.px]="mobileQuery.matches ? 56 : 56">

      <app-sidebar class="app-sidebar"></app-sidebar>

    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" style="position: fixed; background-color: white;" class="chat-panel" opened="false" [mode]="mobileQuery.matches ? 'side' : 'over'" [style.marginTop.px]="tabQuery.matches ? 64 : 64">
      <div style="border-left: 4px solid #a7a7a7; margin-left: 16px;">
        <div class="preloader" *ngIf="isSpinnerVisible">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
        <app-navigation-panel [navigationData]='urls'></app-navigation-panel>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">

      <div class="page-content">

        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
        <div class="text-center display-1">
          &copy; <span style=" font-size: small;">{{currentYear}}</span> noon payments
        </div>
      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->

  </mat-sidenav-container>

</div>
