import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon('icon_introduction',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_introduction.svg'));
    this.matIconRegistry.addSvgIcon('icon_best_practices',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_best_practices.svg'));
    this.matIconRegistry.addSvgIcon('icon_faq',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_faq.svg'));
    this.matIconRegistry.addSvgIcon('icon_contacts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_contacts.svg'));
    this.matIconRegistry.addSvgIcon('icon_announcements',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_announcements.svg'));

    this.matIconRegistry.addSvgIcon('icon_api_authentication',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_api_authentication.svg'));
    this.matIconRegistry.addSvgIcon('icon_datatype',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_datatype.svg'));
    this.matIconRegistry.addSvgIcon('icon_evaluate_response',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_evaluate_response.svg'));
    this.matIconRegistry.addSvgIcon('icon_error_api',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_error_api.svg'));
    this.matIconRegistry.addSvgIcon('icon_test_cards',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_test_cards.svg'));
    this.matIconRegistry.addSvgIcon('icon_test_amounts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_test_amounts.svg'));
    this.matIconRegistry.addSvgIcon('icon_test_crypto',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_test_crypto.svg'));
    this.matIconRegistry.addSvgIcon('icon_postman',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_postman.svg'));

    this.matIconRegistry.addSvgIcon('icon_payment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_payment.svg'));
    this.matIconRegistry.addSvgIcon('icon_tokenization',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_tokenization.svg'));
    this.matIconRegistry.addSvgIcon('icon_emi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_emi.svg'));
    this.matIconRegistry.addSvgIcon('icon_subscription',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_subscription.svg'));
    this.matIconRegistry.addSvgIcon('icon_offers',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_offers.svg'));
    this.matIconRegistry.addSvgIcon('icon_bnpl',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_bnpl.svg'));
    this.matIconRegistry.addSvgIcon('icon_webhook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_webhook.svg'));
    this.matIconRegistry.addSvgIcon('icon_sdk',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_sdk.svg'));

    this.matIconRegistry.addSvgIcon('icon_cards',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_cards.svg'));
    this.matIconRegistry.addSvgIcon('icon_applepay',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_applepay.svg'));
    this.matIconRegistry.addSvgIcon('icon_googlepay',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_googlepay.svg'));
    this.matIconRegistry.addSvgIcon('icon_samsungpay',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_samsungpay.svg'));
    this.matIconRegistry.addSvgIcon('icon_clicktopay',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_clicktopay.svg'));
    this.matIconRegistry.addSvgIcon('icon_paypal',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_paypal.svg'));
    this.matIconRegistry.addSvgIcon('icon_unionpay',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_unionpay.svg'));
    this.matIconRegistry.addSvgIcon('icon_omannet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_omannet.svg'));
    this.matIconRegistry.addSvgIcon('icon_knet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_knet.svg'));
    this.matIconRegistry.addSvgIcon('icon_benefit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_benefit.svg'));
    this.matIconRegistry.addSvgIcon('icon_upg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_upg.svg'));
    this.matIconRegistry.addSvgIcon('icon_crypto',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_crypto.svg'));

    this.matIconRegistry.addSvgIcon('icon_ecommerce',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_ecommerce.svg'));
    this.matIconRegistry.addSvgIcon('icon_opencart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_opencart.svg'));
    this.matIconRegistry.addSvgIcon('icon_magento',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_magento.svg'));
    this.matIconRegistry.addSvgIcon('icon_shopify',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_shopify.svg'));
    this.matIconRegistry.addSvgIcon('icon_woocommerce',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icon_woocommerce.svg'));
  }

}
