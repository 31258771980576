import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/start/introduction',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./features/documentation/documentation.module').then(m => m.DocumentationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
