<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" group="{{menuitem.state}}" [class.individual-page]="menuitem.children == null">
        <!-- Link -->
        <a #linkItem class="nav-menu-item" appAccordionToggle [routerLink]="menuitem.state" *ngIf="menuitem.type === 'link'" [title]="menuitem.name">
            <mat-icon *ngIf="menuitem.icon.type == 'normal'">{{ menuitem.icon.name }}</mat-icon>
            <mat-icon class="nav-svg-menu-item" *ngIf="menuitem.icon.type == 'svg'" [svgIcon]=" menuitem.icon.name"></mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <!-- External Link? -->
        <a class="nav-menu-item" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon *ngIf="menuitem.icon.type == 'normal'">{{ menuitem.icon.name }}</mat-icon>
            <mat-icon class="nav-svg-menu-item" *ngIf="menuitem.icon.type == 'svg'" [svgIcon]=" menuitem.icon.name"></mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <!-- External Tab Link  -->
        <a class="nav-menu-item" appAccordionToggle href="{{menuitem.state}}" target="_blank" rel="noopener" *ngIf="menuitem.type === 'extTabLink'">
            <mat-icon *ngIf="menuitem.icon.type == 'normal'">{{ menuitem.icon.name }}</mat-icon>
            <mat-icon class="nav-svg-menu-item" *ngIf="menuitem.icon.type == 'svg'" [svgIcon]=" menuitem.icon.name"></mat-icon>
            <span>{{ menuitem.name}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a #subItems [attr.menuState]="menuitem.state" class="nav-menu-item" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon *ngIf="menuitem.icon.type == 'normal'">{{ menuitem.icon.name }}</mat-icon>
            <mat-icon class="nav-svg-menu-item" *ngIf="menuitem.icon.type == 'svg'" [svgIcon]=" menuitem.icon.name"></mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected">
                    
                <a #childItem [routerLink]="getRouterLink(menuitem.state, childitem.state)"  *ngIf="childitem.type === 'link'" class="relative"
                    routerLinkActive="selected" (click)="itemSelect[i]=j" [title]="menuitem.name + ' ' +  childitem.name">
                    <mat-icon *ngIf="childitem.icon && childitem.icon.type == 'normal'">{{ childitem.icon.name }}</mat-icon>
                    <mat-icon *ngIf="childitem.icon && childitem.icon.type == 'svg'" [svgIcon]=" childitem.icon.name"></mat-icon>
                    {{ childitem.name}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index">
                <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="itemSelect[i]=j"
                    [ngClass]="j==itemSelect[i]? 'selected' : ''">
                    <span>{{ childitem.name }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'" >
                    <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                        <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]" routerLinkActive="selected" >{{child.name}}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

        </mat-nav-list>

        <div class="separator text-muted separator-override" *ngIf="menuitem.type === 'separator'">
            <span>{{ menuitem.name }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>
