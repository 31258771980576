
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavElems } from '../models/nav-elems';

@Injectable({
  providedIn: 'root'
})
export class ScrollManagerService {
  routerEventSubscription: any;

  getLastStash(url) {
    const arr = url.split('/');
    return arr[arr.length - 1];
  }
  container: NavElems;

  public pageHashUrlElement = new Subject<NavElems>();
  public showSpinner = new Subject<boolean>();

  public showNavLoader(showLoader: boolean) {
    this.showSpinner.next(showLoader);
  }

  public setElementContainer(elems: NavElems) {
    this.pageHashUrlElement.next(elems);
    this.container = elems;

    window.addEventListener('scroll', () => {
      let shortestDistance = 10000000;
      let elem = elems;
      this.traverseElems(elems, function (ele: NavElems) {
        let currectDistance = window.scrollY - ele.offset;
        currectDistance = currectDistance < 0 ? currectDistance * -1 : currectDistance; // converting -ve values to +ve
        if (shortestDistance > currectDistance) {
          shortestDistance = window.scrollY - ele.offset;
          shortestDistance = shortestDistance < 0 ? shortestDistance * -1 : shortestDistance; // converting -ve values to +ve
          elem = ele;
        }
        ele.activated = false; // all activated to false; will be activated only nearest one, that is nearest
      });
      if(elem)
        elem.activated = true;
    });
  }

  traverseElems(elems: NavElems, func) {
    if (elems) {
      func(elems);
      if (elems.elems) {
        elems.elems.forEach(x => {
          if (x) {
            func(x);
            this.traverseElems(x, func);
          }
        });
      }
    }
  }
}

