import { Directive, HostListener, Inject } from '@angular/core';

import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
  selector: '[appAccordionToggle]'
})
export class AccordionAnchorDirective {
  protected navlink: AccordionLinkDirective;

  constructor(@Inject(AccordionLinkDirective) navlink: AccordionLinkDirective) {
    this.navlink = navlink;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    this.navlink.toggle();
      try
      {
      var windowHeight = window.innerHeight;
      var elementTopPost = e.currentTarget.getBoundingClientRect().top;
      var diff = windowHeight - elementTopPost;
      if(diff <= 120)
      {
        e.currentTarget.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
      }
    }catch(err)
    {
    }
  }
}
