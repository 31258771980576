import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  AfterViewInit,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
import { CDN_RELATIVE_PATH } from '../../../core/constant/general.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class AppSidebarComponent implements OnDestroy, OnInit, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  public paymentMethodIconsCDNPath: string = CDN_RELATIVE_PATH.ICONS_PAYMENT_METHODS;

  private _mobileQueryListener: () => void;
  status: boolean = true;
  private found = false;
  private skipNext = false;
  
  itemSelect:number[]= []
  @ViewChildren("childItem") sidebarChildItems: QueryList<ElementRef>
  @ViewChildren("linkItem") sidebarLinkItems: QueryList<ElementRef>
  @ViewChildren("subItems") sidebarSubItems: QueryList<ElementRef>
  private currentRoute: string;

  subclickEvent() {
    this.status = true;
  }
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private route: Router,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }
  ngAfterViewInit(): void {
    this.ScrollItemIntoView();
  }
  ScrollItemIntoView()
  {
    if(this.sidebarLinkItems != null && this.sidebarLinkItems != undefined && this.sidebarLinkItems.length > 0)
    {
      this.getSidebarLinkItemsPresent();
    }
    if(!this.found && this.sidebarChildItems != null && this.sidebarChildItems != undefined && this.sidebarChildItems.length > 0)
    {
      this.skipNext = false;
      this.sidebarChildItems.forEach(childItem => {
        var href = childItem.nativeElement.href as string;
        if(href.toLocaleLowerCase().includes(this.currentRoute.toLocaleLowerCase()))
        {
          this.found = true;
          this.skipNext = true;
          if(this.sidebarSubItems != null && this.sidebarSubItems != undefined && this.sidebarSubItems.length > 0)
          {
            this.getSidebarSubItemsPresent();
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.currentRoute = this.route.url;
  }

  getRouterLink(param1, param2){
    return '/' + param1 + '/' + param2;
  }

  getSidebarLinkItemsPresent(): void {
    this.sidebarLinkItems.forEach(linkItem => {
      var href = linkItem.nativeElement.href as string;
      if(!this.skipNext && href.toLocaleLowerCase().includes(this.currentRoute.toLocaleLowerCase()))
      {
        this.found = true;
        this.skipNext = true;
        linkItem.nativeElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    });
  }

  getSidebarSubItemsPresent(): void {
    var skipSubNext = false;
    var currentRouteSplit = this.currentRoute.split('/');
    var currentRouteParent = "/" + currentRouteSplit[1] + "/" + currentRouteSplit[2];
    this.sidebarSubItems.forEach(subItem => {
      var stateAttribute = subItem.nativeElement.getAttribute("menuState") as string;
      if(!skipSubNext && stateAttribute.toLocaleLowerCase() == currentRouteParent.toLocaleLowerCase())
      {
        skipSubNext = true;
        subItem.nativeElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
